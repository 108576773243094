<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-2">
                {{year}} ©
            </div>
            <div class="col-sm-10">
                <div class="d-none d-sm-block" style="margin-top: -15px;">
                    <img src="assets/images/CHARTE GIZ.png" alt="" height="40" style="margin: 0 40px;">
                    <img src="assets/images/logo-gitas.gif" alt="" height="40" style="margin: 0 40px;">
                    <img src="assets/images/LOGO-TAA.png" alt="" height="50" style="margin: 0 40px;">
                </div>
            </div>
        </div>
    </div>
</footer>