import { constants } from 'src/app/core/helpers/constants';
import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Users',
        icon: 'bxs-user-detail',
        link: '/',
        subItems: [
            {
                id: 2,
                label: 'ListUsers',
                link: '/admin/list-users',
                parentId: 1
            },

        ],
        roleId: constants.ROLE.ADMIN.id
    },
    {
        id: 2,
        label: 'Enquetes',
        icon: 'bx bxs-square',
        subItems: [
            {
                id: 2,
                label: 'Liste des enquêtes',
                link: '/admin/list-sondage',
                parentId: 1
            },

        ],
        roleId: constants.ROLE.ADMIN.id
    },
    {
        id: 3,
        label: 'Satistiques',
        icon: 'bxs-chart',
        link: '/admin/stat',
        subItems: [
            {
                id: 4,
                label: 'Général',
                link: '/admin/stat',
                parentId: 1
            },
            {
                id: 5,
                label: 'Capital Humain',
                link: '/admin/statpersonel',
                parentId: 1
            },
            {
                id: 10,
                label: 'Entreprises par Gouvernourat',
                link: '/admin/statentreprisesgouvernourat',
                parentId: 1
            },
            {
                id: 12,
                label: 'Employes par Gouvernourat',
                link: '/',
                parentId: 1,
                subItems: [
                    {
                        id: 13,
                        label: 'Automobile',
                        link: '/admin/statemployesgouvernouratauto',
                        parentId: 12
                    },
                    {
                        id: 14,
                        label: 'Aéronautique',
                        link: '/admin/statemployesgouvernourataero',
                        parentId: 12
                    },
                ]
            },

            {
                id: 6,
                label: 'Les Activités',
                link: '/admin/statactivity',
                parentId: 1
            },
            {
                id: 7,
                label: 'Les Sous Activités',
                link: '',
                parentId: 1,
                subItems: [
                    {
                        id: 8,
                        label: 'Automobile Principale',
                        link: '/admin/statsousactivityautoprincipale',
                        parentId: 7
                    },
                    {
                        id: 9,
                        label: 'Automobile Secondaire',
                        link: '/admin/statsousactivityautosecondaire',
                        parentId: 7
                    },
                    {
                        id: 11,
                        label: 'Aéronautique Principale',
                        link: '/admin/statsousactivityaeroprincipale',
                        parentId: 7
                    },
                    {
                        id: 12,
                        label: 'Aéronautique Secondaire',
                        link: '/admin/statsousactivityaerosecondaire',
                        parentId: 7
                    },
                ],
            },
            {
                id: 15,
                label: 'Entreprises par CA',
                link: '/admin/statsentreprisesca',
                parentId: 1
            },
            {
                id: 16,
                label: 'Entreprises par tranche de CA',
                link: '/admin/statsentreprisestrancheca',
                parentId: 1
            },
            {
                id: 20,
                label: 'Capacités de production installées',
                link: '/admin/statproductioninstalle',
                parentId: 1
            },
            {
                id: 21,
                label: 'Nombres Entreprises par pays',
                link: '/',
                parentId: 1,
                subItems: [
                    {
                        id: 22,
                        label: 'Aéronautique',
                        link: '/admin/statentreprisespaysaero',
                        parentId: 21
                    },
                    {
                        id: 9,
                        label: 'Automobile',
                        link: '/admin/statentreprisespaysauto',
                        parentId: 7
                    },
                ]
            },
            {
                id: 21,
                label: 'Déchets',
                subItems: [
                    {
                        id: 26,
                        label: 'Taux des déchets revalorisés',

                        link: '/admin/statsdechet',
                        parentId: 1
                    },
                    {
                        id: 26,
                        label: 'Nombre d\'entreprises par type de déchets',
                        link: '/admin/statsalldechet',
                        parentId: 1
                    },

                    {
                        id: 26,
                        label: 'Revalorisation Interne',
                        link: '/admin/revalorisationinterne',
                        parentId: 1
                    },

                    {
                        id: 26,
                        label: 'Impact Carbonne',
                        link: '/admin/impactcarbonne',
                        parentId: 1
                    },
                ]

            },
            {
                id: 30,
                label: 'Nombre d’entreprises par certification',
                link: '/admin/statscertif',
            }
        ],
        roleId: constants.ROLE.ADMIN.id
    },
]