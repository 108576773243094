<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/Logo-API1.webp" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/Logo-API1.webp" alt="" height="50">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/Logo-API1.webp" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/Logo-API1.webp" alt="" height="50">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>


    </div>

    <div class="d-flex">



      <!-- <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
            class="ms-1">{{countryName}} <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i></span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
              class="align-middle">{{item.text | translate}}</span>
          </a>
        </div>
      </div> -->
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <div>
            <span ngbTooltip="" title="{{user.first_name}}  {{user.last_name}}"
                class="header-profile-user rounded-circle bg-soft bg-light text-primary font-size-32" style="padding-left: 8px;">
                {{getFristLettres(user)}}
            </span>
            <span class="d-none d-xl-inline-block ms-1">{{user.first_name}}  {{user.last_name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </div>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'Logout' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</header>
